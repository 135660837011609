.servicehero {
  grid-column: 1 / 4;
  background: url('../../src/img/servicehero.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  height: 179px;
  position: relative;
}

.servicehero::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.49;
  background: #000;
  z-index: 1;
}

.servicehero__title {
  grid-column: 2 / -2;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.31;
  text-align: center;
  align-self: center;
  color: var(--white);
  z-index: 1;
}

@media (min-width: 768px) {
  .servicehero {
    height: 358px;
  }

  .servicehero__title {
    font-size: 4.5rem;
  }
}
