.imprint > * {
  grid-column: 2 / -2;
}

.imprint {
  padding: 2em 0 3em 0;

  h2 {
    margin-top: 1em;
    font-size: 1.25rem;
  }

  h3 {
    margin-top: 1em;
  }

  p {
    margin: 0.5em 0 0.5em 0;
  }

  h2,
  h3,
  p {
    min-height: 0;
  }
}

@media (max-width: 300px) {
  .imprint {
    h2 {
      font-size: 0.8rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
}
