.service-conclusion {
  background: #f6f7f8;
  padding: 4.5em 0;
}

.service-conclusion > * {
  grid-column: 2 / -2;
}

.service-conclusion__image-wrapper {
  justify-self: center;
  border-radius: 50%;
  height: 225px;
  width: 225px;
}

.service-conclusion__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: grayscale(100%);
  opacity: 0.6;
}

.service-conclusion__title {
  font-size: 1.625rem;
  line-height: 1.62;
  text-align: center;
  margin-top: 1.875rem;
  color: var(--primary);

  span {
    font-weight: 300;
  }
}

.service-conclusion__list {
  list-style: none;
  padding-left: 0;
  color: var(--text-color);
  justify-self: center;
}

.service-conclusion__listitem,
.fas__conclusion {
  font-size: 1rem;
  align-self: start;
  margin: 1em 1em 1em 0;
  justify-self: center;
}

.fas__conclusion {
  font-size: 0.8rem;
  margin: 0;
}

@media (max-width: 300px) {
  .service-conclusion__title {
    font-size: 1rem;
  }

  .service-conclusion__listitem {
    font-size: 0.65rem;
  }

  .fas__conclusion {
    font-size: 0.6rem;
  }
}

@media (min-width: 768px) {
  .service-conclusion {
    padding: 4em 0 5em 0;
  }

  .service-conclusion__image-wrapper {
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    margin-left: 3.5em;
    align-self: center;
    width: 300px;
    height: 300px;
  }

  .service-conclusion__title {
    grid-column: 3 / 5;
    grid-row: 2 / 3;
    font-size: 2rem;
    line-height: 1.31;
    text-align: start;
    margin-top: 0;
    margin-left: 3em;
  }

  .service-conclusion__list {
    grid-column: 3 / 5;
    grid-row: 3 / 4;
    justify-self: start;
    margin: 0;
    transform: translateX(33%);
  }

  .service-conclusion__listitem {
    font-size: 1rem;
    align-self: start;
    margin: 1em 1em 1em 0;
    max-width: 350px;
  }

  .fas__conclusion {
    font-size: 1rem;
  }
}

@media (min-width: 880px) {
  .service-conclusion__listitem {
    max-width: 500px;
  }
}

@media (min-width: 1024px) {
  .service-conclusion__image-wrapper {
    width: 375px;
    height: 375px;
  }
}
