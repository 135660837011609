.blog-header {
  grid-column: 1 /4;
  background: url('../../src/img/blog-header.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  height: 179px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background: #000;
    z-index: 1;
  }
}

.blog-header__title {
  grid-column: 2 / -2;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.31;
  color: var(--white);
  z-index: 1;
}

@media (min-width: 768px) {
  .blog-header {
    grid-column: 1 / 7;
    grid-row: 1 / 2;
    margin-bottom: 3rem;
    height: 358px;
  }

  .blog-header__title {
    font-size: 2.5rem;
  }
}
