.memberships {
  padding: 2.25em 0 2.25em 0;
}

.memberships,
.memberships > * {
  grid-column: 2 / -2;
}

.memberships__title {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 3;
  letter-spacing: 1.08px;
  color: var(--secondary);
}

.memberships__subtitle {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.62;
  margin-left: 0;
  color: var(--primary);
  transform: translateY(-10%);

  span {
    font-weight: 300;
  }
}

.memberships__text {
  line-height: 1.5;
  margin: 1.75em 0 3em 0;
  color: var(--text-color);
}

.memberships__wrapper {
  padding: 1.5rem;
  border-radius: 17px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.13);
  background-color: #f6f7f8;
}

.memberships__wrapper:last-child {
  margin-top: 1.25em;
}

.memberships__logo {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.nawik {
  width: 126px;
  height: 85px;
}

.memberships__name {
  font-size: 1rem;
  line-height: 1.75;
  overflow-wrap: break-word;
  margin: 1em 0 0.25em 0;
  color: var(--primary);
}

.memberships__linktext {
  text-decoration: none;
  font-size: 0.9rem;
  line-height: 2.5;
  color: var(--secondary);

  &:hover,
  &:focus {
    cursor: pointer;
    color: var(--primary);
  }
}

@media (max-width: 300px) {
  .memberships__title {
    font-size: 0.8rem;
  }

  .memberships__subtitle {
    font-size: 1rem;
    transform: translateY(-10%);
  }

  .memberships__text {
    font-size: 0.8rem;
    margin-top: 1em;
  }

  .memberships__name {
    font-size: 0.75rem;
  }

  .memberships__linktext {
    font-size: 0.7rem;
    overflow-wrap: break-word;
  }
}

@media (min-width: 768px) {
  .memberships {
    padding: 8.25em 0 3.25em 0;
  }

  .membership__content-wrapper {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    margin-right: 2em;
  }

  .memberships__wrapper {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .memberships__title {
    font-size: 1.25rem;
  }

  .memberships__subtitle {
    font-size: 1.5rem;
    line-height: 1.31;
    margin-bottom: 0.75em;
    transform: translateY(-10%);
  }

  .memberships__name {
    font-size: 1.25rem;
    line-height: 1.75;
    margin-top: 2em;
  }

  .memberships__wrapper:last-child {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
    margin-top: 0;

    .memberships__name {
      margin: 1em 0 0.25em 0;
    }
  }
}

@media (min-width: 1024px) {
  .memberships__subtitle {
    font-size: 2rem;
  }
}
