.blog__wrapper {
  margin-bottom: 3em;
}

@media (min-width: 768px) {
  .blog__wrapper {
    display: grid;
    grid-template-columns:
      minmax(1em, 1fr) repeat(3, minmax(20px, 295px)) 200px
      minmax(1em, 1fr);
    grid-template-rows: repeat(8, minmax(1em, auto));
  }
}
