* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: var(--primary);
  background: var(--white-color);
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

.main__grid {
  display: grid;
  grid-template-columns: minmax(1em, 1fr) minmax(0, 500px) minmax(1em, 1fr);
  grid-gap: 1em;
}

@media (min-width: 768px) {
  .main__grid {
    grid-template-columns:
      minmax(1em, 1fr) repeat(3, minmax(20px, 370px))
      minmax(1em, 1fr);
  }
}
