.publications {
  padding: 2.25em 0 5.5em;
}

.publications,
.publications > * {
  grid-column: 2 / -2;
}

.publications__title {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 3;
  letter-spacing: 1.08px;
  text-align: center;
  color: var(--secondary);
}

.publications:nth-child(5) {
  padding-bottom: 10em;
}

.publications__subtitle {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.31;
  text-align: center;
  margin-bottom: 1em;
  color: var(--primary);
  transform: translate(-2%, -50%);
}

.publications__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 17px;
  padding: 0.75em 1.4em;
  border: solid 3px #edeeef;
}

.pubications__date {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 3;
  letter-spacing: 1.08px;
  text-align: start;
  color: var(--secondary);
}

.pubications__headline {
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.36;
  margin-bottom: 0.5em;
  color: var(--primary);
}

.pubications__text {
  font-size: 1rem;
  line-height: 1.54;
  margin-left: 0;
  color: var(--text-color);
}

.publications__cta--linktext {
  font-weight: 400;
  line-height: 2.63;
  letter-spacing: 1.23px;
  text-decoration: none;
  margin: 1em auto;
  color: var(--secondary);

  &::after {
    content: '\f061';
    width: 24px;
    height: 24px;
    font-size: 0.7rem;
    margin-left: 0.75em;
    border-radius: 50%;
    align-items: center;
    color: var(--secondary);
    background: #e0a36233;
    vertical-align: middle;
    text-align: center;
    line-height: 22px;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    transform: scale(1.1);
  }
}

@media (max-width: 300px) {
  .publications__title {
    font-size: 0.8rem;
  }

  .publications__subtitle {
    font-size: 1rem;
  }

  .pubications__headline {
    font-size: 0.8rem;
  }

  .pubications__date,
  .pubications__text {
    font-size: 0.7rem;
  }

  .publications__cta--linktext {
    font-size: 0.6rem;

    &::after {
      width: 14px;
      height: 14px;
      font-size: 0.4rem;
      line-height: 14px;
    }
  }
}

@media (min-width: 768px) {
  .publications {
    padding: 8em 0 7.75em 0;
  }

  .publications__wrapper {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  .publications__wrapper:nth-last-of-type(2) {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
  }

  .publications__wrapper:last-child {
    grid-column: 4 / 5;
    grid-row: 3 / 4;
    margin-bottom: 0;
  }

  .pubications__headline {
    font-size: 1rem;
  }

  .publications__title {
    font-size: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .pubications__headline {
    font-size: 1.375rem;
  }
}
