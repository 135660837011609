.services > * {
  grid-column: 2 / -2;
}

.services__title {
  margin-top: 2em;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 3;
  letter-spacing: 1.08px;
  text-align: center;
  color: var(--secondary);
}

.services__subtitle {
  font-size: 1.6rem;
  line-height: 1.54;
  text-align: center;
  margin: 0 0 2em 0;
  color: var(--primary);
  transform: translateY(-10%);
}

.services__subtitle > span {
  font-weight: 700;
}

.services__description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 17px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.11), 0 1px 4px 0 rgba(0, 0, 0, 0.13);
  background-color: var(--white);
  padding: 2em;
  margin-bottom: 0.5em;

  &:hover,
  &:focus {
    cursor: pointer;
    background: var(--secondary);
    box-shadow: 0 16px 36px 0 rgba(0, 0, 0, 0.11);
  }
}

.services__description--icon {
  width: 54px;
  height: 54px;
  background-color: rgba(209, 128, 39, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .services__description:hover & {
    background: #eccfaf33;
  }
}

.fas {
  font-size: 1.5rem;
  color: var(--secondary);

  .services__description:hover & {
    color: var(--white);
  }
}

.services__description--name {
  font-size: 1.3rem;
  line-height: 1.75;
  text-align: start;
  color: var(--primary);
  margin: 0.5em 0;
  word-break: break-word;

  .services__description:hover & {
    color: var(--white);
  }
}

.services__description--name > span {
  font-weight: 400;
}

.services__description--text {
  font-size: 1rem;
  line-height: 1.63;
  color: var(--text-color);
  margin-left: 0;

  .services__description:hover & {
    color: var(--white);
  }
}

.services__cta--link {
  font-weight: 400;
  line-height: 2.63;
  letter-spacing: 1.23px;
  text-decoration: none;
  text-align: end;
  color: var(--white);
  visibility: hidden;
  margin-top: 1em;

  &::after {
    content: '\f061';
    width: 24px;
    height: 24px;
    font-size: 0.7rem;
    margin-left: 0.75em;
    border-radius: 50%;
    color: var(--white);
    background: #e7e0d433;
    text-align: center;
    line-height: 24px;
  }

  &:hover,
  &:focus {
    transform: scale(1.1);
  }

  .services__description:hover & {
    visibility: visible;
  }
}

@media (max-width: 300px) {
  .services__subtitle,
  .services__description--name {
    font-size: 1rem;
  }

  .services__description--name {
    overflow-wrap: break-word;
  }

  .services__description--cta {
    justify-content: start;
  }

  .services__description--text {
    font-size: 0.8rem;
    margin-left: 0;
  }

  .services__description--linktext {
    font-size: 0.65rem;
  }
}

@media (min-width: 768px) {
  .services {
    grid-template-columns:
      minmax(1em, 1fr) repeat(3, minmax(5px, auto))
      minmax(1em, 1fr);
  }

  .services__title {
    margin-top: 3.875em;
  }

  .services__subtitle {
    grid-column: 2 / 5;
    font-size: 2rem;
    line-height: 1.31;
    max-width: 900px;
    margin: 0 auto;
    transform: translateY(-20%);
  }

  .writing {
    grid-column: 2 / 3;
  }

  .communication {
    grid-column: 4 / 5;
  }

  .writing,
  .communication {
    max-width: 550px;
  }

  .services__description {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.13);
  }

  .services__description--name {
    font-size: 1.5rem;
  }

  .services__cta--link {
    &:hover,
    &:focus {
      transform: scale(1.05);
    }
  }
}
