.header {
  display: grid;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0;
}

.header__logo {
  grid-column: 2 / -2;
  grid-row: 1 / 2;
}

.header__logo-image {
  grid-column: 2 / -2;
  grid-row: 1 / 2;
  height: 95px;
  width: 175px;
  object-fit: cover;
}

.header__title {
  font-size: 1.125rem;
  margin-bottom: 0;
}

.header__subtitle {
  font-size: 0.625rem;
  color: var(--text-color);
  margin-top: 0;
  font-weight: 400;
  max-width: 250px;
}

@media (max-width: 300px) {
  .header__logo-image {
    width: 150px;
    height: 65px;
    margin-bottom: 0;
  }

  .header__title {
    font-size: 0.8rem;
  }

  .header__subtitle {
    font-size: 0.6rem;
  }
}

@media (min-width: 340px) {
  .header__logo-image {
    width: 200px;
  }
}

@media (min-width: 768px) {
  .header__logo {
    grid-column: 2 / 3;
    width: 275px;
  }

  .header__title {
    font-size: 1.5rem;
  }

  .header__subtitle {
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--secondary);
    width: 325px;
  }
}

@media (min-width: 900px) {
  .header__logo {
    grid-column: 2 / 3;
    width: 325px;
  }

  .header__logo-image {
    width: 350px;
    margin-bottom: 1em;
  }
}
