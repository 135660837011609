.credits__wrapper {
  padding: 2em 0 6em 0;
}

.credits__wrapper > * {
  grid-column: 2 / -2;
}

.credits__title {
  margin-bottom: 0.75em;
}

.credits__title--website {
  margin-top: 1.25em;
}

.credits__paragraph {
  margin: 0;
  font-size: 0.75rem;
}

.credits__link {
  color: var(--primary);

  &:hover,
  &:focus {
    color: var(--text-color);
    opacity: 0.7;
  }
}

@media (min-width: 768px) {
  .credits__wrapper {
    padding: 12em 0 16em 0;
  }

  .credits__title,
  .credits__title--website {
    font-size: 2.5rem;
    margin-bottom: 0.75em;
  }

  .credits__paragraph {
    font-size: 1.125rem;
  }
}
