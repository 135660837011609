.nav {
  position: fixed;
  background: var(--white);
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 150%;
  transform: translateX(0);
  transition: transform 250ms;
  z-index: 3;
}

.nav__item {
  width: 100vh;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(209, 128, 39, 0.2);
}

.nav__list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 1.25rem;
}

.nav__link {
  text-decoration: none;
  color: var(--secondary);
}

.nav__open {
  grid-column: 2 / -2;
  grid-row: 1 / 2;
  border: 0;
  background: 0;
  z-index: 3;
  color: var(--secondary);
  padding: 0;
  margin-left: auto;
  font-size: 3em;
  transform: translateY(-5%) {

  }

  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.navigation__open {
  transform: translateX(-150%);
  z-index: 3;

  li {
    animation: fadeIn cubic-bezier(0.3, -0.01, 0.49, 1) 625ms forwards;
    opacity: 0;

    &:nth-child(1) {
      animation-delay: 125ms;
    }

    &:nth-child(2) {
      animation-delay: 250ms;
    }

    &:nth-child(3) {
      animation-delay: 375ms;
    }

    &:nth-child(4) {
      animation-delay: 500ms;
    }

    &:nth-child(5) {
      animation-delay: 625ms;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(150%);
  }

  75% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.nav__close {
  border: 0;
  background: 0;
  z-index: 99;
  color: var(--secondary);
  font-weight: 400;
  font-size: 3rem;
  padding: 0.5em;
  position: absolute;

  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.nav__link:hover,
.nav__link:focus,
.nav__close:hover,
.nav__close:focus,
.nav__open:hover,
.nav__open:focus {
  color: var(--secondary);
  cursor: pointer;

  &:active {
    color: var(--primary);
  }
}

.current {
  border-bottom: 1px solid var(--secondary);
}

@media (max-width: 377px) {
  .nav {
    left: 275%;
  }

  .nav__list {
    font-size: 1rem;
  }

  .nav__open,
  .nav__close {
    font-size: 1.75rem;
  }

  .navigation__open {
    transform: translateX(-275%);
  }
}

@media (min-width: 768px) {
  .nav__open,
  .nav__close {
    display: none;
  }

  .nav {
    position: initial;
    grid-column: 3 / 5;
    max-width: 450px;
    justify-self: end;
  }

  .nav__item {
    width: auto;
    height: auto;
    border-bottom: none;
  }

  .nav__list {
    flex-direction: row;
    justify-content: flex-end;
  }

  .nav__link {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.46;
    letter-spacing: 0.87px;
    margin-right: 1.5em;
    color: var(--text-color);
  }

  .contact__link {
    margin-right: 0;
  }

  .current {
    color: var(--secondary);
    border-bottom: 1px solid var(--secondary);
  }

  .nav__link--logo {
    margin: 0;
  }
}
