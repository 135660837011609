.education {
  background-color: #f6f7f8;
  padding: 2.25em 0 3.5em 0;
}

.education,
.education > * {
  grid-column: 2 / -2;
}

.education__title {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 3;
  letter-spacing: 1.08px;
  color: var(--secondary);
}

.education__subtitle {
  margin: 0 0 1em 0;
}

.education__name {
  line-height: 2.63;
  color: var(--primary);
  margin: 0;
  font-weight: 300;
  font-size: 0.7rem;

  &::before {
    content: '\f111';
    margin-right: 0.5em;
  }
}

.education__subtitle--pharma {
  margin: 2em 0 1em 0;
}

.education__subtitle,
.education__subtitle--pharma {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.62;
  color: var(--primary);

  span {
    font-weight: 300;
  }
}

@media (max-width: 300px) {
  .education__title {
    font-size: 0.8rem;
  }

  .education__subtitle,
  .education__subtitle--pharma {
    font-size: 1rem;
    transform: translateY(-30%);
  }

  .education__name {
    font-size: 0.5rem;
    line-height: 1.63;
  }
}

@media (min-width: 768px) {
  .education {
    background-image: url('../../src/img/graduate.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-color: #f6f7f8;
    padding: 3.3em 0 5.5em, 0;
  }

  .education__title {
    font-size: 1.25rem;
    margin: 0;
  }

  .education__subtitle {
    transform: translateY(0);
    align-self: center;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .education__subtitle,
  .education__subtitle--pharma {
    margin: 0 0 1em 0;
  }

  .education-flex__wrapper {
    grid-column: 2 / 5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .education__wrapper {
    margin-right: 3em;
  }

  .pharma__wrapper {
    margin-right: auto;
  }

  .education__name {
    font-size: 0.75rem;
  }
}

@media (min-width: 1024px) {
  .education__wrapper {
    margin-right: 6em;
  }

  .education__name {
    font-size: 1rem;
  }

  .education__subtitle,
  .education__subtitle--pharma {
    font-size: 2rem;
  }
}
