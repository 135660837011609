.hero {
  grid-column: 1 /4;
  background: url('../../src/img/hamburg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-blend-mode: multiply;
  height: 323px;
  position: relative;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0) 255% 0%);
  z-index: 1;
}

.hero__contentwrapper {
  grid-column: 2 / -2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-self: center;
  height: 75%;
  z-index: 2;
}

.hero__title {
  font-size: 1.5rem;
  line-height: 1.21;
  max-width: 191px;
  position: relative;
  padding-left: 0.5em;

  &::before {
    content: '';
    height: 82%;
    width: 4px;
    background-color: var(--secondary);
    position: absolute;
    transform: translateY(10%);
    left: 0;
    top: 0;
  }

  span {
    font-weight: normal;
  }
}

.hero__subtitle {
  font-size: 1rem;
  line-height: 1.21;
  font-weight: 700;
  margin-left: 0;

  span {
    font-weight: normal;
  }
}

.hero__text {
  max-width: 273px;
  font-size: 0.75rem;
  line-height: 1.64;
  margin-left: 0;
  color: var(--primary);
}

.hero__btnwrapper {
  display: flex;
  margin-top: 0.5em;
}

.hero__btn--services {
  background: var(--secondary);
  color: var(--white);
  margin-right: 1em;
  text-align: center;
}

.hero__btn--contact {
  background: var(--white);
  color: var(--secondary);
}

.hero__btn--services,
.hero__btn--contact {
  width: 128px;
  height: 37px;
  border-radius: 23.6px;
  font-size: 0.88rem;
  font-weight: 400;
  letter-spacing: 0.27px;
  border: 1px solid var(--secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover,
  &:focus {
    cursor: pointer;
    transform: scale(1.1);
  }
}

@media (max-width: 300px) {
  .hero__title {
    font-size: 1.25rem;
    line-height: 1;
  }

  .hero__subtitle {
    font-size: 0.9rem;
  }

  .hero__text {
    max-width: 273px;
    font-size: 0.75rem;
    line-height: 1.45;
    color: var(--primary);
  }

  .hero__contentwrapper {
    height: 70%;
  }

  .hero__btn--services,
  .hero__btn--contact {
    width: 75px;
    height: 25px;
    font-size: 0.35rem;
    letter-spacing: 0.15px;
  }
}

@media (min-width: 768px) {
  .hero {
    height: 575px;
  }

  .hero__contentwrapper {
    grid-column: 2 / 4;
    height: 55%;
  }

  .hero__title {
    max-width: 500px;
    font-size: 2.75rem;
    line-height: 1.29;

    &::before {
      height: 58%;
      transform: translateY(40%);
    }
  }

  .hero__subtitle {
    font-size: 1.25rem;
  }

  .hero__text {
    max-width: 750px;
    font-size: 1rem;
    line-height: 1.63;
  }

  .hero__btn--services,
  .hero__btn--contact {
    width: 160px;
    height: 46px;
  }
}

@media (min-width: 1024px) {
  .hero__contentwrapper {
    grid-column: 2 / 4;
    height: 55%;
  }

  .hero__title {
    max-width: 500px;
    font-size: 2.75rem;
    line-height: 1.29;

    &::before {
      height: 60%;
      transform: translateY(35%);
    }
  }
}
