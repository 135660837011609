.blog {
  grid-column: 2 / -2;
}

.article__container {
  border-radius: 17px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.12);
  background-color: #f4f5f6;
  margin-bottom: 0.5em;

  time {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 3;
    letter-spacing: 1.08px;
    color: var(--secondary);
  }
}

.article__contentwrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0.5em 1.5em 0 1.5em;
}

.article__headline {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.36;
  margin-left: 0;
  color: var(--primary);
}

.articles__author--icon {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 3;
  letter-spacing: 1.08px;
  text-align: center;
  color: var(--text-color);
  margin-left: 0;
  padding-bottom: 0.15em;
  border-bottom: solid 1px rgb(92, 125, 131, 0.16);
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  &::before {
    content: '\f007';
    font-weight: 300;
    margin-right: 0.5em;
    color: var(--text-color);
  }
}

.articles__cta--link {
  font-weight: 400;
  line-height: 2.63;
  letter-spacing: 1.23px;
  text-decoration: none;
  text-align: center;
  margin: 0.75em 0;
  color: var(--secondary);

  &::after {
    content: '\f061';
    width: 24px;
    height: 24px;
    font-size: 0.7rem;
    margin-left: 0.75em;
    border-radius: 50%;
    align-items: center;
    color: var(--secondary);
    background: #c2903933;
    vertical-align: middle;
    text-align: center;
    line-height: 24px;
  }

  &:hover,
  &:focus {
    transform: scale(1.1);
  }
}

.featured {
  margin-bottom: 1.5em;
}

.featured__article--image {
  height: 168px;
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
}

.article__featured-text {
  margin: 0.75em 0;
}

@media (max-width: 300px) {
  .article__headline {
    font-size: 1rem;
  }

  .article__contentwrapper > time {
    font-size: 0.75rem;
  }

  .articles__author--icon {
    font-size: 0.6rem;
  }

  .articles__cta--link {
    font-size: 0.7rem;

    &::after {
      content: '\f061';
      width: 15px;
      height: 15px;
      font-size: 0.6rem;
      line-height: 1.5em;
    }
  }

  .article__featured-text {
    font-size: 0.75rem;
  }
}

@media (min-width: 768px) {
  .blog {
    display: grid;
    grid-template-columns: repeat(3, minmax(1em, 295px));
    grid-template-rows: repeat(2, minmax(1em, 50%));
    gap: 1em;
    grid-column: 2 / 5;
    grid-row: 2 / 6;
  }

  .featured {
    grid-column: 1 / 4;
    grid-row: 1 / 4;
    padding-bottom: 1em;
    margin: 0 0 2em 0;
  }

  .featured__article--image {
    height: 300px;
    width: 100%;
    object-fit: cover;
    border-top-right-radius: 17px;
    border-top-left-radius: 17px;
  }

  .article__featured-text {
    font-size: 16px;
    line-height: 1.5;
    margin: 0.75em 0 0 0;
    color: var(--text-color);
  }

  .featured-articles__cta--link {
    margin-top: 1.25em;
  }

  .featured2 {
    grid-column: 1 / 4;
    grid-row: 4 / 8;
    padding-bottom: 1em;
  }
}

@media (min-width: 889px) {
  .articles__cta--link {
    margin-top: 1.75em;
  }
}
