.contact {
  margin: 6em 0;
}

.contact__content--overlay {
  display: none;
}

.contact__description {
  background: #ed7600;
  grid-column: 1 / 4;
  background-image: url('../../src/img/contact-bg.svg');
  background-repeat: no-repeat;
  background-position: -380% 137%;
}

.contact__content {
  grid-column: 2 / -2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-self: center;
  padding: 2em 0;
}

.contact__content--title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 1em;
  color: var(--white);
}

.contact__content--text {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  margin: 2em 0;
  color: var(--white);
}

.contact__details {
  list-style: none;
  padding-left: 0;
  color: var(--white);
  margin-top: 0;

  li {
    margin: 0.5em 0;
  }
}

.contact__links {
  text-decoration: none;
  color: var(--white);

  &:hover,
  &:focus {
    opacity: 0.6;
    text-decoration: underline;
  }
}

.contact__email,
.contact__phone,
.contact__adress {
  margin-right: 1em;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.71;
  color: var(--white);
}

.contact__email::before,
.contact__phone::before,
.contact__adress::before {
  color: var(--white);
  margin-right: 1em;
}

.contact__email::before {
  content: '\f0e0';
}

.contact__phone::before {
  content: '\f879';
}

.contact__adress::before {
  content: '\f276';
  margin-right: 1.6em;
}

.form {
  grid-column: 2 / -2;
  display: flex;
  flex-direction: column;
  margin-top: 1.5em;

  input,
  textarea {
    border-radius: 4px;
    border: solid 1px #bfcccd;
    opacity: 0.7;
    outline: none;
  }

  input {
    height: 52px;
    padding: 1em;
    margin: 1em 0;
    font-size: 1rem;
  }

  textarea {
    padding: 1em 0.5em;
    margin-top: 1.5em;
    margin-bottom: 0;
    resize: none;
    font-family: 'Poppins', sans-serif;
  }

  .checkboxwrapper {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    @media (min-width: 768px) {
      grid-column: 2 / 4;
      grid-row: 5 / 6;
    }

    .checkbox {
      width: 16px;
      margin: 0 0.5em 0 0;
      padding: 0;

      &:checked {
        background: var(--secondary);
      }

      &:hover,
      &:focus {
        cursor: pointer;
      }
    }

    .checkboxlabel {
      font-size: 0.55rem;

      @media (min-width: 1024px) {
        font-size: 0.65rem;
      }
    }
  }
}

.data-link {
  color: var(--primary);

  &:hover,
  &:focus {
    cursor: pointer;
    opacity: 0.4;
  }
}

.contact__button {
  width: 115px;
  height: 37px;
  border-radius: 23.6px;
  font-size: 0.88rem;
  font-weight: 400;
  letter-spacing: 0.27px;
  background: var(--secondary);
  color: var(--white);
  border: none;
  align-self: center;
  margin-top: 3em;

  &:hover,
  &:focus {
    cursor: pointer;
    transform: scale(1.1);
  }

  &::after {
    content: '\f1d8';
    font-size: 0.85rem;
    margin-left: 0.5em;
    color: var(--white);
  }
}

@media (max-width: 300px) {
  .contact {
    margin-bottom: 2em;
  }

  .contact__content--title {
    font-size: 1rem;
  }

  .contact__content--text {
    font-size: 0.8rem;
  }

  .contact__email,
  .contact__phone,
  .contact__adress,
  .contact__email::before,
  .contact__phone::before,
  .contact__adress::before {
    font-size: 0.6rem;
  }

  .form {
    margin-top: 1.5em;

    input {
      height: 32px;
      font-size: 0.8rem;
    }

    textarea {
      font-size: 0.8rem;
    }
  }

  .contact__button {
    width: 75px;
    height: 25px;
    font-size: 0.5rem;
    letter-spacing: 0.15px;
    margin: 2em 0;
  }

  .contact__button::after {
    font-size: 0.65rem;
  }
}

@media (min-width: 414px) {
  .contact__description {
    background-position: -2500% 155%;
  }
}

@media (min-width: 736px) {
  .contact__description {
    background-position: 170% 250%;
  }
}

@media (min-width: 768px) {
  .contact {
    margin-top: 10em;
    margin-bottom: 12em;
  }

  .contact__description {
    grid-column: 1 / 4;
    grid-row: 1 / 4;
    height: 650px;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    background-position: 115% 145%;
  }

  .contact__content {
    grid-column: 2 / 4;
    grid-row: 1 / 4;
    height: 80%;
    max-width: 225px;
  }

  .contact__content--title {
    font-size: 1.5rem;
    margin-top: 0.25em;
  }

  .contact__content--text {
    font-size: 0.9rem;
  }

  .form {
    grid-column: 3 / 6;
    grid-row: 2 / 3;
    display: grid;
    grid-template-columns:
      minmax(1em, 1fr) repeat(2, minmax(20px, 325px))
      minmax(1em, 1fr);
    grid-template-rows:
      minmax(1em, 1fr) repeat(3, minmax(20px, auto))
      minmax(1em, 1fr);
    gap: 1em;
    z-index: 1;
    background: var(--white);
    padding: 0 1em 2em 1em;
    height: 475px;
    margin-top: 0;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    box-shadow: 0 24px 32px 0 rgba(0, 0, 0, 0.16);

    input {
      margin: 0.25em 0;
    }

    textarea {
      margin-top: 1em;
    }
  }

  .first__name {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .last__name {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

  .email {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  .tel {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
  }

  .txtarea {
    grid-column: 2 / 4;
    grid-row: 4 / 5;
    margin-bottom: 0;
  }

  .contact__button {
    grid-column: 3 / 4;
    grid-row: 6 / 7;
    justify-self: end;
    margin: 1em 0;
  }
}

@media (min-width: 800px) {
  .contact__description {
    background-position: 95% 145%;
  }
}

@media (min-width: 825px) {
  .contact__description {
    background-position: 90% 145%;
  }
}

@media (min-width: 850px) {
  .contact__description {
    background-position: 85% 145%;
  }
}

@media (min-width: 900px) {
  .contact__description {
    background-position: 75% 145%;
  }

  .contact__content {
    max-width: 275px;
  }
}

@media (min-width: 950px) {
  .contact__description {
    background-position: 65% 145%;
  }
}

@media (min-width: 1000px) {
  .contact__description {
    background-position: 64% 145%;
  }

  .contact__content {
    max-width: 300px;
  }
}

@media (min-width: 1112px) {
  .contact__description {
    background-position: 62% 155%;
    height: 629px;
  }

  .contact__content {
    max-width: 325px;
  }
}

@media (min-width: 1195px) {
  .contact__description {
    background-position: 61% 155%;
  }

  .contact__content {
    display: none;
  }

  .contact__content--overlay {
    display: inline-block;
    z-index: 0;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    color: var(--white);
  }

  .contact__content--text {
    font-size: 1rem;
  }

  .form {
    grid-column: 3 / 6;
    padding: 1em 2em 2.5em 2em;
  }
}

@media (min-width: 1250px) {
  .contact__description {
    background-position: 65% 155%;
  }
}

@media (min-width: 1366px) {
  .contact__description {
    background-position: 68% 155%;
  }
}

@media (min-width: 1500px) {
  .contact__description {
    background-position: 72% 155%;
  }
}

@media (min-width: 1650px) {
  .contact__description {
    background-position: 75% 155%;
  }
}

@media (min-width: 1920px) {
  .contact__description {
    background-position: 80% 155%;
  }
}
