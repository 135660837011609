.footer {
  background: var(--primary);
  color: var(--white);
  padding: 3em 0 0 0;
}

.footer > * {
  grid-column: 2 / -2;
}

.footer__logo-wrapper {
  max-width: 300px;
  max-height: 100px;
}

.footer__logo-dark {
  object-fit: cover;
  width: 100%;
}

.footer__contact {
  list-style: none;
  padding-left: 0;
  color: var(--white);
  margin-top: 0;

  li {
    margin: 0.5em 0;
  }
}

.footer__email,
.footer__phone,
.footer__adress {
  margin-right: 1em;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.71;
  color: var(--white);
}

.footer__email::before,
.footer__phone::before,
.footer__adress::before {
  color: var(--white);
  margin-right: 1em;
}

.footer__email::before {
  content: '\f0e0';
}

.footer__phone::before {
  content: '\f879';
}

.footer__adress::before {
  content: '\f276';
  margin-right: 1.6em;
}

.footer__navlinks {
  display: flex;
  flex-direction: column;
}

.footer__navlinks--title,
.footer__social--title {
  font-size: 0.9rem;
  color: var(--secondary);
  font-weight: 400;
  margin-bottom: 1em;
}

.footer__link,
.footer__social--link {
  text-decoration: none;
  color: var(--text-color);
  margin: 0.25em 0;
  font-weight: 400;

  &:hover,
  &:focus {
    color: var(--white);
  }
}

.footer__social {
  margin-bottom: 2em;
}

.fa-xing-square,
.fa-linkedin {
  margin-right: 0.25em;
}

.fa-xing-square,
.fa-linkedin,
.fa-instagram-square {
  font-size: 1.5rem;
  color: var(--text-color);

  &:hover,
  &:focus {
    color: var(--white);
    transform: scale(1.2);
  }
}

.footer__link-wrapper {
  margin-bottom: 0.5em;
}

.dataprotection {
  grid-column: 1 / 4;
  padding: 2.5em 0;
  background: #1c1b1b;
}

.dataprotection > * {
  grid-column: 2 / -2;
}

.dataprotection__link-wrapper {
  margin-left: 0;
}

.dataprotection__link {
  opacity: 0.94;
  font-size: 0.9rem;
  color: var(--white);
  text-align: start;

  &:hover,
  &:focus {
    cursor: pointer;
    font-weight: 700;
  }
}

.dataprotection__copyright {
  opacity: 0.48;
  font-size: 0.9rem;
  color: var(--white);
  margin: 0.5em 0 0 0;
}

@media (max-width: 300px) {
  .footer__email,
  .footer__phone,
  .footer__adress,
  .footer__email::before,
  .footer__phone::before,
  .footer__adress::before {
    font-size: 0.6rem;
  }

  .footer__navlinks--title,
  .footer__social--title {
    font-size: 0.65rem;
  }

  .footer__link,
  .footer__social--link {
    font-size: 0.6rem;
  }

  .fa-xing-square,
  .fa-linkedin,
  .fa-instagram-square {
    font-size: 1rem;
  }

  .dataprotection__link,
  .dataprotection__copyright,
  .and {
    font-size: 0.65rem;
  }
}

@media (min-width: 768px) {
  .footer {
    padding-top: 3em;
  }

  .footer__logo-wrapper {
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    max-width: 300px;
    max-height: 100px;
    margin-bottom: 0.25em;
  }

  .footer__logo-dark {
    object-fit: cover;
    width: 100%;
  }

  .footer__contact {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
  }

  .footer__navlinks {
    grid-column: 3 / 4;
    grid-row: 3 / 5;
    justify-self: center;
  }

  .footer__social {
    grid-column: 4 / 5;
    grid-row: 3 / 5;
    justify-self: center;
    margin-bottom: 0;
  }

  .footer__link {
    &:nth-of-type(1) {
      margin-top: 0;
    }
  }

  .footer__link-wrapper {
    margin-bottom: 4.25em;
  }

  .dataprotection {
    grid-column: 1 / 6;
    grid-row: 5 / 6;
    margin-top: 2em;
    width: 100%;
  }

  .dataprotection > * {
    grid-column: 2 / 5;
    margin-left: 2em;
  }

  .dataprotection__link-wrapper,
  .dataprotection__copyright {
    margin-left: 0;
  }

  .dataprotection__link {
    text-decoration: none;
  }
}
