.sidebar {
  grid-column: 2 / -2;
}

.sidebar__contentwrapper {
  border-radius: 6px;
  background: #f4f5f6;
  padding: 1em;
  height: 100%;
}

.sidebar__title,
.sidebar__categories {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  line-height: 1.38;
  color: var(--primary);

  &::after {
    content: '';
    height: 0.1em;
    vertical-align: middle;
    width: 100%;
    margin-left: 0.5em;
    border-bottom: 1px solid var(--secondary);
  }
}

.sidebar__articlewrapper {
  margin-top: 0.75em;
  border-bottom: solid 1px rgb(92, 125, 131, 0.16);
}

.sidebar__articlewrapper:nth-of-type(1) {
  margin-top: 1.25em;
}

.sidebar__articlewrapper:nth-of-type(3) {
  border: none;
}

.sidebar__article--date {
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.92px;
  color: var(--secondary);
}

.sidebar__article--headline {
  font-size: 0.9rem;
  margin-left: 0;
  font-weight: 400, bold;
  line-height: 1.36;
}

.sidebar__linkwrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-author {
  font-size: 0.6rem;
  border: none;
}

.sidebar__cta--link {
  font-weight: 400;
  line-height: 2.63;
  letter-spacing: 1.23px;
  text-decoration: none;
  text-align: center;
  color: var(--secondary);

  &::after {
    content: '\f061';
    width: 18px;
    height: 18px;
    font-size: 0.5rem;
    border-radius: 50%;
    align-items: center;
    color: var(--secondary);
    background: #c2903933;
    vertical-align: middle;
    text-align: center;
    line-height: 18px;
  }

  &:hover,
  &:focus {
    transform: scale(1.3);
  }
}

.sidebar__categories {
  margin-top: 1em;
}

.sidebar__categories--hero {
  margin-top: 1.5em;
  font-weight: 700;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 64px;
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.43;
    border-radius: 6px;
    background: #de7c00;
    z-index: 1;
  }

  &:hover,
  &:focus {
    opacity: 0.2;
  }
}

.medicine {
  background: url('../../src/img/pills.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}

.health {
  background: url('../../src/img/coral.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}

.economics {
  background: url('../../src/img/globe.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}

.random {
  background: url('../../src/img/pill.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}

.all {
  background: url('../../src/img/medicine.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}

.sidebar__category--title {
  z-index: 2;
  font-size: 1rem;
}

@media (max-width: 300px) {
  .sidebar__article--date {
    font-size: 0.75rem;
  }

  .sidebar__article--headline {
    font-size: 0.8rem;
  }

  .sidebar__cta--link {
    font-size: 0.7rem;

    &::after {
      content: '\f061';
      width: 15px;
      height: 15px;
      font-size: 0.6rem;
      line-height: 1.5em;
    }
  }

  .sidebar__categories--hero {
    height: 47px;
  }

  .sidebar__category--title {
    font-size: 0.8rem;
  }
}

@media (min-width: 768px) {
  .sidebar {
    grid-column: 5 / 6;
    grid-row: 2 / 6;
    display: grid;
    grid-template-columns: minmax(1em auto);
    grid-template-rows: minmax(1em auto);
  }

  .sidebar__contentwrapper {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
}
