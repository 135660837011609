.testimonials {
  background: var(--grey-bg);
}

.testimonials > * {
  grid-column: 2 / -2;
}

.testimonials__title,
.testimonials__subtitle {
  text-align: center;
}

.testimonials__title {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1.08px;
  color: var(--secondary);
  margin-top: 3rem;
}

.testimonials__subtitle {
  font-size: 1.9rem;
  line-height: 1.31;
  color: var(--primary);
  margin-left: 0;
  font-weight: 700;
  margin-bottom: 1em;
  transform: translateY(-10%);

  span {
    font-weight: 400;
  }
}

.testimonials__wrapper {
  display: grid;
  grid-template-columns: minmax(1em auto);
  grid-template-rows: repeat(5, minmax(1em auto));
}

.testimonials__logo {
  height: 258px;
  border-radius: 11.9px;
  background-color: var(--white);
  box-shadow: 0 24px 32px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;
  grid-row: 3 / 4;
}

.testimonials__logo--image {
  width: 70%;
  object-fit: cover;
}

.active-logo {
  text-align: center;
}

.prev-logo,
.next-logo {
  display: none;
}

.prev {
  align-self: center;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.next {
  align-self: center;
  justify-self: end;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.prev,
.next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 45px;
  font-size: 1rem;
  color: var(--secondary);
  background-color: var(--grey-bg);
  border: none;
  padding: 0;
  grid-column: 1 / -1;
  grid-row: 3 / 4;

  &:hover,
  &:focus {
    cursor: pointer;
    background: var(--secondary);
    color: var(--white);
  }
}

.testimonials__progessbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2.25em 0;
  grid-column: 1 / -1;
  grid-row: 4 / 5;
}

.testimonials__progessbar-dots {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: solid 1px var(--secondary);
  margin: 0.5em;
}

.active-dot {
  width: 16px;
  height: 10px;
  border-radius: 5px;
  background: var(--secondary);
}

@media (max-width: 300px) {
  .testimonials__title,
  .testimonials__subtitle {
    font-size: 1rem;
  }

  .next,
  .prev {
    width: 25px;
    height: 30px;
    font-size: 0.5rem;
  }
}

@media (min-width: 768px) {
  .testimonials__title {
    font-size: 1.25rem;
  }

  .testimonials__wrapper {
    grid-column: 2 / 5;
    grid-row: 3 / 4;
    display: grid;
    grid-template-columns: repeat(6, minmax(20px 320px));
  }

  .prev-logo,
  .next-logo {
    display: inherit;
    height: 233px;
    border-radius: 17px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.07);
  }

  .next-logo {
    z-index: 1;
    grid-column: 3 / 6;
    grid-row: 3 / 4;
    justify-content: end;
    padding-right: 4em;
  }

  .prev-logo {
    z-index: 1;
    grid-column: 1 / 4;
    grid-row: 3 / 4;
    justify-content: start;
    padding-left: 4em;
  }

  .active-logo {
    z-index: 2;
    height: 275px;
    border-radius: 17px;
    box-shadow: 0 24px 32px 0 rgba(0, 0, 0, 0.16);
    grid-column: 2 / 5;
    grid-row: 3 / 4;

    h3 {
      font-size: 3rem;
    }

    p {
      font-size: 1.5rem;
    }
  }

  .next-logo,
  .prev-logo,
  .active-logo {
    align-self: center;
  }

  .testimonials__logo--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .testimonials__progessbar {
    grid-column: 3 / 4;
    grid-row: 4 / 5;
  }

  .prev,
  .next {
    grid-column: 1 / 6;
    grid-row: 3 / 4;
    z-index: 1;
  }
}

@media (min-width: 1024px) {
  .next,
  .prev {
    width: 44px;
    height: 59px;
    font-size: 1.15rem;
  }
}
