.data-protection > * {
  grid-column: 2 / -2;
}

.data-protection {
  padding: 2em 0 3em 0;

  h1 {
    margin: 0.5em 0 0.5em 0;
  }

  h2 {
    margin: 2em 0 0.5em 0;
  }

  h3 {
    margin-top: 2em;
  }

  h4 {
    margin: 1em 0 0.5em 0;
  }

  p,
  ul {
    margin: 0.5em 0 0.5em 0;
  }

  li {
    margin-bottom: 1em;
  }
}

@media (max-width: 300px) {
  .data-protectionx {
    h2 {
      font-size: 0.8rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
}
