.profile {
  margin-top: 5em;
  padding-bottom: 1em;
}

.profile > * {
  grid-column: 2 / -2;
}

.profile__title,
.profile__subtitle,
.profile__text {
  text-align: center;
}

.profile__title {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 3;
  letter-spacing: 1.08px;
  color: var(--secondary);
}

.profile__subtitle {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.31;
  transform: translateY(-40%);
  color: var(--primary);

  span {
    font-weight: 400;
  }
}

.profile__text {
  font-size: 1rem;
  line-height: 1.63;
  color: var(--text-color);
  margin-bottom: 1em;
  transform: translateY(-40%);
}

.profile__imgage-wrapper {
  grid-column: 1 / 4;
}

.profile__image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.profile__description {
  border-radius: 17px;
  box-shadow: 0 24px 32px 0 rgba(0, 0, 0, 0.16);
  background-color: var(--white);
  transform: translateY(-7%);
  padding: 1.25em 1em 1em 1em;
  display: flex;
  flex-direction: column;
}

.profile__description--list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.profile__description--bullet-points {
  font-size: 1rem;
  font-weight: 700;
  color: var(--primary);
  max-width: 225px;
  height: 51px;
  border-radius: 5px;
  background-color: #f6f7f8;
  margin: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.profile__description--bullet-points::before {
  font-size: 1.5rem;
  margin: 0 0.5em;
}

.atom::before {
  content: '\f5d2';
}

.pills::before {
  content: '\f484';
}

.manager::before {
  content: '\f508';
}

.hand::before {
  content: '\f2b5';
}

p {
  margin-left: 0.5em;
}

.profile__description-text {
  line-height: 1.63;
  color: var(--text-color);
  margin-top: 1em;
}

.profile__description--button {
  display: flex;
  text-decoration: none;
  width: 128px;
  height: 37px;
  border-radius: 23.6px;
  font-size: 0.88rem;
  font-weight: 400;
  letter-spacing: 0.27px;
  border: none;
  justify-content: center;
  align-items: center;
  background: var(--secondary);
  margin-top: 2em;
  margin-bottom: 1em;
  align-self: center;
  color: var(--white);

  &:hover,
  &:focus {
    cursor: pointer;
    transform: scale(1.1);
  }
}

@media (max-width: 300px) {
  .profile__title,
  .profile__subtitle,
  .profile__text {
    font-size: 0.8rem;
  }

  .profile__description--bullet-points {
    height: 41px;
    font-size: 0.7rem;

    &::before {
      font-size: 0.7rem;
      margin: 0 0.5em;
    }
  }

  .profile__description-text {
    font-size: 0.65rem;
  }

  .profile__description--button {
    width: 75px;
    height: 25px;
    font-size: 0.5rem;
    letter-spacing: 0.15px;
    margin: 2em 0;
  }
}

@media (min-width: 768px) {
  .profile {
    margin-top: 10.8em;
    margin-bottom: 10.25em;
  }

  .profile > h3 {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    text-align: start;
    font-size: 1rem;
  }

  .profile__subtitle {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    text-align: start;
    margin-left: 0;
  }

  .profile__text {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
    text-align: start;
    margin-left: 0;
  }

  .profile__imgage-wrapper {
    grid-column: 3 / 6;
    grid-row: 2 / 6;
    height: 488px;
  }

  .profile__image {
    border-top-left-radius: 17px;
  }

  .profile__description {
    max-height: 625px;
    max-width: 400px;
    grid-column: 2 / 4;
    grid-row: 5 / 6;
    margin: 2em 0 0 0;
    padding: 1em;
    display: grid;
    grid-template-columns:
      minmax(1em, 1fr) repeat(3, minmax(20px, auto))
      minmax(1em, 1fr);
    grid-template-rows:
      minmax(1em, 1fr) repeat(3, minmax(20px, auto))
      minmax(1em, 1fr);
  }

  .profile__description--list {
    grid-column: 2 / 5;
    justify-content: start;
    display: grid;
    height: 125px;
    margin: 1em 0;
  }

  .profile__description--bullet-points {
    line-height: 1.5;
    padding-right: 1em;
    margin: 0 0.5em;
  }

  .atom {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    margin-bottom: 0.5em;
  }

  .pills {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    margin-bottom: 0.5em;
  }

  .manager {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .hand {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

  .profile__description-text {
    grid-column: 2 / 5;
    grid-row: 4 / 5;
    margin-top: 2em;
  }

  .profile__description--button {
    grid-column: 2 / 3;
    grid-row: 5 / 6;
    display: flex;
    justify-content: center;
    margin-left: 0.5em;
    margin-top: 1em;
  }
}

@media (min-width: 895px) {
  .profile > h3 {
    font-size: 1.25rem;
  }

  .profile__description {
    max-width: 450px;
    max-height: 575px;
  }
}

@media (min-width: 1024px) {
  .profile__description {
    margin-top: 2em;
  }
}

@media (min-width: 1150px) {
  .profile__description {
    max-width: 500px;
  }
}

@media (min-width: 1500px) {
  .profile__image {
    height: 650px;
  }

  .profile__description {
    max-width: 550px;
    max-height: 550px;
  }
}
