.about-me {
  padding: 2.5em 0 3.5em 0;
}

.about-me,
.about-me > * {
  grid-column: 2 / -2;
}

.about-me__image-wrapper {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  justify-self: center;
  align-self: start;
}

.about-me__avatar {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.about-me__content-wrapper {
  margin-top: 2em;
}

.about-me__title {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 3;
  letter-spacing: 1.08px;
  text-align: center;
  color: var(--secondary);
}

.about-me__subtitle {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.62;
  text-align: center;
  margin-bottom: 1em;
  color: var(--primary);
  transform: translateY(-10%);

  span {
    font-weight: 300;
  }
}

.about-me__text {
  line-height: 1.5;
  color: var(--text-color);
  margin: 0 0 1em 0;
}

@media (max-width: 300px) {
  .about-me__title {
    font-size: 0.8rem;
  }

  .about-me__subtitle {
    font-size: 1rem;
    transform: translateY(-10%);
  }

  .about-me__image-wrapper {
    width: 150px;
    height: 150px;
  }

  .about-me__text {
    font-size: 0.8rem;
  }
}

@media (min-width: 768px) {
  .about-me {
    padding: 4em 0 5.5625em 0;
  }

  .about-me__image-wrapper {
    grid-column: 2 / 3;
    grid-row: 2 / 5;
    margin-top: 2em;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    align-self: center;
  }

  .about-me__avatar {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .about-me__content-wrapper {
    grid-column: 3 / 5;
    grid-row: 2 / 5;
    display: flex;
    flex-direction: column;
    margin-left: 2em;
    margin-top: 0;
  }

  .about-me__title {
    font-size: 1.25rem;
  }

  .about-me__title,
  .about-me__subtitle {
    text-align: start;
  }

  .about-me__subtitle {
    margin-left: 0;
    margin-bottom: 0.5em;
    font-size: 2rem;
    transform: translateY(-15%);
  }
}

@media (min-width: 950px) {
  .about-me__image-wrapper {
    width: 300px;
    height: 300px;
  }
}

@media (min-width: 1024px) {
  .about-me__subtitle {
    transform: translateY(-25%);
  }
}

@media (min-width: 1100px) {
  .about-me__image-wrapper {
    width: 350px;
    height: 350px;
  }
}
