.service {
  padding: 2.65em 0 3.875em 0;
}

.service > * {
  grid-column: 2 / -2;
}

.service__title {
  font-size: 1.625rem;
  line-height: 1.62;
  text-align: center;
  margin-bottom: 0.25em;
  color: var(--primary);

  span {
    font-weight: 300;
  }
}

.service__text {
  font-size: 0.8em;
  line-height: 1.5;
  margin-bottom: 1.2em;
  color: var(--text-color);
}

.my-service {
  pointer-events: none;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.13);
}

.my-service__list--wrapper {
  display: flex;
}

.service__list,
.fa-ul {
  color: var(--text-color);
  font-size: 0.6rem;
  font-weight: 300;
  transform: translateX(-3%);
}

.service__bullets {
  margin: 0.5em 0;
}

.fa-check-circle {
  font-size: 0.6rem;
  margin-right: 0.5em;
  color: var(--secondary);
}

@media (min-width: 502px) {
  .service__text {
    font-size: 1em;
  }

  .service__list {
    font-size: 1rem;
  }

  .fa-check-circle {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .service {
    padding: 4em 0 6em 0;
  }

  .my-service {
    grid-column: 2 / 5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1140px;

    &:nth-of-type(1) {
      margin-top: 2.875em;
    }
  }

  .service__title {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    align-self: center;
    text-align: start;
  }

  .headline {
    grid-column: 3 / 5;
    grid-row: 1 / 2;
    padding-left: 1em;
    justify-self: center;
    margin: 0;
    line-height: 1.5;
    border-left: 4px solid var(--secondary);
  }

  .my-service__content-wrapper {
    width: 50%;
  }

  .my-service__list--wrapper {
    padding: 0.5em 1.75em 0.5em 0.5em;
    width: 45%;
    height: 100%;
    border-radius: 17px;
    margin-left: 1em;
    background-color: #f6f7f8;
  }

  .service__list {
    align-self: center;
    justify-self: center;
  }

  .service__list,
  .fa-ul {
    transform: translateX(0%);
  }

  .service__bullets {
    margin: 1.25em 0;
  }
}
